import React, { useState, useRef, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import {
  loginCustomer,
  resetPassword,
  changePassword,
} from '../../services/auth'
import { COLORS, SPACING } from '../../helpers/constants'
import { useTranslation } from '../../hooks/useTranslation'
import { validateField } from '../../helpers/utilities'

import { colors } from '../../styles/utilities/variables'
import { AppContext, setUser } from '../../config/context'

import { Heading } from '../Heading'
import { Divider } from '../Divider'
import { Button } from '../Button'
import { StyledGrid, PageContent, Content } from './style'
import { Form } from '../Form'
import { Loader } from '../Svg'
import { TextLink } from '../TextLink'
import { useResponsive } from '../../hooks/useResponsive'

const Login = () => {
  const [state, dispatch] = useContext(AppContext)
  const { user } = state

  const getTranslation = useTranslation()
  const [resetMessage, setResetMessage] = useState('')
  const [currentForm, setCurrentForm] = useState('login')
  const [loading, setLoading] = useState(false)

  const responsive = useResponsive()
  const maxBtnWidth = responsive?.windowSize?.width < 768 ? '100%' : '350px'

  const [login, setLogin] = useState({
    username: '',
    password: '',
  })

  const [resetPw, setResetPw] = useState({ email: '', valid: '' })
  const [pwForm, setPwForm] = useState({
    password: '',
    repeat: '',
    valid: false,
  })

  let reset = ''
  let key = ''
  let userId = ''

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(document.location.search.substring(1))
    reset = urlParams.get('reset')
    key = urlParams.get('key')
    userId = urlParams.get('id')
  }

  useEffect(() => {
    if (reset && key && userId) {
      setCurrentForm('resetfinal')
    } else {
      setCurrentForm('login')
    }
  }, [reset])

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      navigate('/account')
    }
  }, [])

  const updateUsername = e => {
    setLogin({ ...login, username: e.target.value })
  }

  const updatePassword = e => {
    setLogin({ ...login, password: e.target.value })
  }

  const handleResetPW = e => {
    const pw = e.target.value
    setPwForm({ ...pwForm, password: pw })
  }

  const handleResetPWRepeat = e => {
    const repeat = e.target.value
    setPwForm({ ...pwForm, repeat: repeat })
  }

  const updateResetPwEmail = e => {
    e.preventDefault()

    let email = e.target.value
    let valid = validateField('email', email)

    if (!valid) {
      resetPasswordForm[0].innerRef.current.style.borderColor =
        colors.primaryRed
    } else {
      resetPasswordForm[0].innerRef.current.style.borderColor =
        colors.borderColor
    }

    setResetPw({ email: e.target.value, valid: valid })
  }

  const inputFields = [
    {
      value: login.username,
      onChange: updateUsername,
      placeholder: getTranslation('username'),
      type: 'text',
      innerRef: useRef(null),
    },
    {
      value: login.password,
      onChange: updatePassword,
      placeholder: getTranslation('password'),
      type: 'password',
      innerRef: useRef(null),
    },
  ]

  const resetPasswordForm = [
    {
      value: resetPw.email,
      onChange: updateResetPwEmail,
      placeholder: getTranslation('email'),
      type: 'text',
      innerRef: useRef(null),
    },
  ]

  const pwresetForm = [
    {
      value: pwForm.password,
      onChange: handleResetPW,
      placeholder: getTranslation('password'),
      type: 'password',
    },
    {
      value: pwForm.repeat,
      onChange: handleResetPWRepeat,
      placeholder: getTranslation('confirm password'),
      type: 'password',
    },
  ]

  const loginError = () => {
    inputFields[0].innerRef.current.style.borderColor = colors.primaryRed
    inputFields[1].innerRef.current.style.borderColor = colors.primaryRed
  }

  const loginOk = () => {
    inputFields[0].innerRef.current.style.borderColor = colors.borderColor
    inputFields[1].innerRef.current.style.borderColor = colors.borderColor
  }

  const handleLogin = e => {
    e.preventDefault()
    setLoading(true)
    loginCustomer(login)
      .then(res => {
        if (res && res.token) {
          dispatch(setUser(res))
          if (typeof window !== 'undefined') {
            navigate('/account')
          }
        }
      })
      .catch(err => {
        loginError()
        setLoading(false)
      })
  }

  const handleResetPassword = e => {
    e.preventDefault()
    setLoading(true)
    resetPassword(resetPw.email)
      .then(response => {
        if (response.message) {
          setResetMessage(response.message)
        }
        setLoading(false)
      })
      .catch(Err => {
        setLoading(false)
      })
  }

  const handleUpdatePassword = e => {
    e.preventDefault()
    setLoading(true)
    changePassword({
      key: key,
      user: userId,
      password: pwForm.password,
      repeat: pwForm.repeat,
    })
      .then(response => {
        if (response.message) {
          setResetMessage(response.message)
          setTimeout(() => {
            if (typeof window !== 'undefined') {
              navigate('/login')
            }
          }, 500)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const resetLinkForm = () => {
    return (
      <>
        <Heading size={2} type={'h3'} color={COLORS.PRIMARY_RED}>
          {getTranslation('Reset password')}
        </Heading>
        <Divider size={SPACING.SMALL} />
        <Form>
          {resetPasswordForm.map((field, index) => {
            return (
              <div key={index}>
                <Form.Input {...field} />
                <Divider size={SPACING.SMALL} />
              </div>
            )
          })}
          <Button
            size={'large'}
            color={COLORS.PRIMARY_DARK_BLUE}
            fullWidth={true}
            disabled={!resetPw.email || !resetPw.valid}
            maxWidth={maxBtnWidth}
            handleClick={handleResetPassword}
          >
            {getTranslation('Reset link')}
            {loading && <Loader />}
          </Button>
          <Divider size={SPACING.X_SMALL} />
          {resetMessage ? (
            <>
              <Content maxWidth={maxBtnWidth}>
                <Form.Label>{resetMessage}</Form.Label>
                <Divider size={SPACING.X_SMALL} />
              </Content>
            </>
          ) : (
            ''
          )}
          <Content
            maxWidth={maxBtnWidth}
            onClick={() => {
              setCurrentForm('login')
            }}
          >
            <TextLink
              to={'/login'}
              text={getTranslation('Login instead')}
              underline={true}
            ></TextLink>
          </Content>
        </Form>
      </>
    )
  }

  const loginForm = () => {
    return (
      <>
        <Heading size={2} type={'h3'} color={COLORS.PRIMARY_RED}>
          {getTranslation('Sing in')}
        </Heading>
        <Divider size={SPACING.SMALL} />
        <Form>
          {inputFields.map((field, index) => {
            return (
              <div key={index}>
                <Form.Input {...field} />
                <Divider size={SPACING.SMALL} />
              </div>
            )
          })}
          <Button
            size={'large'}
            color={COLORS.PRIMARY_DARK_BLUE}
            fullWidth={true}
            disabled={!login.password || !login.username}
            maxWidth={maxBtnWidth}
            handleClick={handleLogin}
          >
            {loading ? getTranslation('logging in') : getTranslation('Login')}
            {loading && <Loader />}
          </Button>
          <Divider size={SPACING.X_SMALL} />
          <Content
            maxWidth={maxBtnWidth}
            onClick={() => {
              setCurrentForm('resetlink')
            }}
          >
            <TextLink
              to='#'
              text={getTranslation('Forgot password?')}
              underline={true}
            ></TextLink>
          </Content>
        </Form>
      </>
    )
  }

  const resetPasswordFinalStep = () => {
    return (
      <>
        <Heading size={2} type={'h3'} color={COLORS.PRIMARY_RED}>
          {getTranslation('Reset password')}
        </Heading>
        <Divider size={SPACING.SMALL} />
        <Form>
          {pwresetForm.map(field => {
            return (
              <>
                <Form.Input {...field} />
                <Divider size={SPACING.SMALL} />
              </>
            )
          })}
          <Button
            size={'large'}
            color={COLORS.PRIMARY_DARK_BLUE}
            fullWidth={true}
            disabled={!pwForm.password || !pwForm.repeat}
            maxWidth={maxBtnWidth}
            handleClick={handleUpdatePassword}
          >
            {getTranslation('Update password')}
            {loading && <Loader />}
          </Button>
          <Divider size={SPACING.X_SMALL} />
          {resetMessage ? (
            <>
              <Content maxWidth={maxBtnWidth}>
                <Form.Label>{resetMessage}</Form.Label>
                <Divider size={SPACING.X_SMALL} />
              </Content>
            </>
          ) : (
            ''
          )}
          <Content
            maxWidth={maxBtnWidth}
            onClick={() => {
              setCurrentForm('login')
            }}
          >
            <TextLink
              to={'/login'}
              text={getTranslation('Login instead')}
              underline={true}
            ></TextLink>
          </Content>
        </Form>
      </>
    )
  }

  const renderForm = current => {
    if (current === 'login') {
      return loginForm()
    }

    if (current === 'resetlink') {
      return resetLinkForm()
    }

    if (current === 'resetfinal') {
      return resetPasswordFinalStep()
    }
  }

  return (
    <StyledGrid>
      <PageContent>
        {renderForm(currentForm)}
        <Divider size={SPACING.XXX_LARGE} />
        <Divider size={SPACING.XXX_LARGE} />
      </PageContent>
    </StyledGrid>
  )
}

export { Login }
